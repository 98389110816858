<template>
    <div class="login_main">
        <!-- 左边表单部分 -->
        <div class="left_div">
            <div class="logoDiv">
                <img src="../../assets/imgs/lg.png" alt="">
            </div>
            <div class="welcome">
                <div class="our">我们是 <span>全一优教</span></div>
                <div class="home">欢迎回来,请登录您的账户。</div>
            </div>
            <div class="ta_from">
                <el-form label-width="80px" label-position="top">
                    <el-form-item style="margin-bottom: 20px;">
                        <!-- <div class="bl" v-if="show"></div> -->
                        <el-input prefix-icon="el-icon-user" v-model="mobile" placeholder="请输入账号" clearable size="mini">
                        </el-input>
                    </el-form-item>
                    <el-form-item>
                        <!-- <div class="be" v-if="show1"></div> -->
                        <el-input type="password" prefix-icon="el-icon-lock" show-password v-model="password"
                            placeholder="请输入密码(6-20位)" clearable size="mini"></el-input>
                    </el-form-item>
                    <div class="formBtn">
                        <el-button @click="handle_loginGo">登录</el-button>
                    </div>
                </el-form>
            </div>
        </div>
        <!-- 右边图片部分 -->
        <div class="right_div">
            <img src="../../assets/imgs/78.png" alt="">
        </div>
    </div>
</template>

<script>
import { getLoginApi } from '@/api'
import { initRouter } from '@/router'
export default {
    data () {
        return {
            mobile: '',
            password: '',
            show: false,
            show1: false,
        }
    },
	mounted() {
		document.addEventListener('keydown', this.keyDown)
	},
    methods: {
		keyDown(e) {
			console.log(e);
			if(e.keyCode == 13) {
				this.handle_loginGo()				
			}
		},
        handle_zh () {
            this.show = true;
            this.show1 = false
        },
        handle_pwd () {
            this.show = false;
            this.show1 = true;
        },
        handle_loginGo () {
            getLoginApi({
                mobile: this.mobile,
                password: this.password
            }).then(res => {
                if (res.status.code == 200) {
                    console.log(res)
                    sessionStorage.setItem('id', res.data.user_info.user_id);    //存储用户id
                    sessionStorage.setItem('Token', res.data.user_info.access_token);  //存储token
                    this.$store.commit('setLeftList', res.data.auth);   //存储用户权限
                    this.$store.commit('setUserName', res.data.user_info.user_name);  //存储用户名
                    initRouter();
                    this.$message.success('登录成功！');
                    this.$router.push({ path: '/welcome' });
                } else if (res.status.code == 201) {
                    this.$message.error(res.status.msg)
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.login_main {
    width: 100%;
    height: 100%;
    display: flex;
    background: #ffffff;
    // 左边表单
    .left_div {
        width: 45%;
        // height: 100%;
        // padding: 40px 0 0 200px;
        padding: 40px 0 0 100px;
        // logo部分
        .logoDiv {
            text-align: left;
            margin-bottom: 44px;
            img {
                width: 62px;
                height: 66px;
            }
        }
        // 欢迎语部分
        .welcome {
            text-align: left;
            .our {
                font-size: 32px;
                font-weight: 600;
                color: #999999;
                margin-bottom: 16px;
                span {
                    color: #333333;
                }
            }
            .home {
                // width: 140px;
                font-size: 16px;
                font-weight: 400;
                color: #999999;
                margin-bottom: 120px;
            }
        }
        //表单部分
        .ta_from {
            .el-form {
                text-align: left;
                .el-form-item {
                    width: 492px;
                    height: 40px;
                    border: 1px solid #dadada;
                    border-radius: 6px;
                    margin-bottom: 0px;
                    ::v-deep.el-form-item__label {
                        // vertical-align: bottom;
                        font-size: 14px;
                        font-weight: 400;
                        color: #999999;
                        text-indent: 20px;
                        padding-bottom: 0px;
                    }
                    ::v-deep.el-input__inner {
                        border: none;
                    }
                }
            }
            .formBtn {
                margin-top: 80px;
                // margin-bottom: 20px;
                text-align: left;
                .el-button {
                    width: 152px;
                    height: 50px;
                    background: #2749dd;
                    color: #ffffff;
                    font-size: 14px;
                    font-weight: 600;
                    border-radius: 6px;
                }
            }
        }
    }
    // 右边图片
    .right_div {
        width: 50%;
        height: 100%;
        background: url(../../assets/imgs/hg.png) no-repeat center;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        img {
            width: 392px;
            height: 392px;
        }
        .img1 {
            z-index: 999999;
            position: absolute;
            left: 130px;
            top: 110px;
        }
    }
}
</style>